import { useState, useReducer, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import localforage from 'localforage';
import { isEmpty } from "lodash";


import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { initState } from '../../reducers/middleware';
import { reducer } from '../../reducers';
import "./admin.css";
import { Link } from 'react-router-dom';

 const Admin = () => {
  const [state, dispatch] = useReducer(reducer, initState)
  const { users } = state;
  const [updatedUsers, setUpdatedUsers] = useState(users);
  const [idDelete, setIdDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  async function fetchUsers() {
    localforage.getItem('user', (err, data) => {
      if (err) {
        console.log('error', err)
        return ""
      }
      try {
        fetch(`${process.env.REACT_APP_API_URL}/user/authenticated/getAll`, { 
          method: "POST",
          credentials: "include",
          mode: "cors",
          body: JSON.stringify({
            token: data.token 
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((d) => d.json())
        .then((json) => {
          if (!json.error) {
            setUpdatedUsers(json?.users)
            dispatch({type: "setUsers", payload: json?.users})
          }
        })
        // maybe also log the error 
      } catch (error) {
        console.log(error)
      }
    })
    }
  useEffect(() => {
    if (!isEmpty(users.length > 0)) {
      setIsLoading(false)
    }
      if (isLoading) {
        fetchUsers()
      }
  }, [users.length, isLoading])
  const handleUpdateValue = async (e) => {
    const { target } = e;
    const id = parseInt(target.getAttribute("data-id"));
    const key = target.getAttribute("data-key")
    const { value } = target;
    const user = updatedUsers.find(usr => usr.id === id);
    user[key] = parseInt(value === "" ? 0 : value);
    setUpdatedUsers(
      updatedUsers.map((prev) => prev.id === id ? user : prev)
    )
    
  }

  const handleSave = async (e) => {
    localforage.getItem('user', (err, data) => {
      if (err) {
        console.log('error', err)
        return ""
      }
      try {
        const { target } = e;
        const id = target.getAttribute("data-id");
        const user = updatedUsers.find(usr => usr.id === parseInt(id));
        fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            token: data.token,
            id,
            tacos: parseInt(user?.tacos),
            tequila: parseInt(user?.tequila),
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then((data) => {
          return data.json()
        })
        // maybe also log the error 
      } catch (error) {
        console.log(error)
      }
    })
  }

  const options = () => {
    return updatedUsers.map((user) => {
      return {
        value: user.id,
        label: user.name,
      }
    })
  }

  const handleConfirm = () => {
    const { value: id } = idDelete;
    const user = updatedUsers.find(usr => usr.id === parseInt(id));
    confirmAlert({
      title: 'Confirm to delete',
      message: `Delete ${user.name}`,
      buttons: [
        {
          label: 'Yes',
          onClick: handleDelete
        },
        {
          label: 'No',
          onClick: () => setIdDelete(null)
        }
      ]
    });
  }
  const handleDelete = async () => {
    localforage.getItem('user', (err, data) => {
      if (err) {
        console.log('error', err)
        return ""
      }
      try {
        const { value: id } = idDelete
        fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, {
          method: "DELETE",
          credentials: "include",
          body: JSON.stringify({
            token: data.token,
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then((data) => {
          return data.json()
        })
        // maybe log error here
      } catch (error) {
        console.log(error)
      }
    })
  }
  return (
    <>
      <h1>Admin</h1>
      <div className="usersContainer">
        {users && users.sort((a,b) => a.id - b.id).map((user) => {
          return (
            <div className="userContainer" key={user.id}>
              <span>{user.name}</span>
              <label>Tacos<input onChange={handleUpdateValue} data-id={user.id} data-key="tacos" className="adminInput" type="number" value={user.tacos}></input></label>
              <label>Tequila<input onChange={handleUpdateValue} data-id={user.id} data-key="tequila" className="adminInput" type="number" value={user.tequila}></input></label>
              <Button data-id={user.id} onClick={handleSave}>Save</Button>
            </div>
          )
        })}
      </div>
      {
        users && (
          <div className="userDelete">
            <Select
              className="reactSelect"
              value={idDelete}
              onChange={(e) => setIdDelete(e)}
              options={options()}
            />
            <Button onClick={handleConfirm}>DELETE</Button>
          </div>
        )
      }
      <Link  to="/" >Back</Link>
    </>
  );
};

export default Admin;