import { GoogleLogin } from 'react-google-login';
import localforage from 'localforage';

const Login = (props) => {
  const { handleDispatch, setStatus } = props;

  const handleLoginSuccess = (googleData) => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/login`, {
      method: "POST",
      credentials: "include",
      mode: "cors",
      body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then((data) => {
      return data.json()
    }).then((userData) => {
      const toStore = {
        email: userData.user?.email,
        token: userData?.token,
      }
      localforage.setItem('user', toStore).then(() => {
        handleDispatch({type: 'setUser', payload: userData.user})
        setStatus(true)
      })
    }).catch(e => console.log(e))
  }
  const handleLoginFailure = (googleData) => {
    console.log("FAIL", googleData)
  }
  return (
    <div id="signInButton">
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        theme="dark"
        buttonText="Login"
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
        cookiePolicy="none"
        isSignedIn={true}
      />
    </div>
  )
}

export default Login;