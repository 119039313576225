import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Admin from "./components/admin/Admin";
import Results from "./components/results/Results";

 const App = () => {
  return (
    <>
      <Routes>
          <Route path="/" element={<Results />} />
          <Route path="/track" element={<Home />} />
          <Route path="admin" element={<Admin />} />
      </Routes>
    </>
  );
};

export default App;