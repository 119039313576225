import "./Results.css"

const Results = () => {
  return (
    <div className="results-container">
      <h1 className="header">Results Pending</h1>
    </div>
  )
}

export default Results;
