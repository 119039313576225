export const reducer = (state, action) => {
  const { payload, type } = action
  switch (type) {
    case 'setUser':
      return {
        ...state,
        user: payload,
      }
    case 'setUsers':
      return {
        ...state,
        users: payload,
      }
    case 'removeUser':
      return {
        user: {},
        users: [],
        charData: {
          labels: [],
          dataSets: [],
        }
      }
    case 'setBarChart':
      return {
        ...state,
        charData: {
          labels: payload.map(user => user.name.split(" ")[0]),
          datasets: [{
            id: 1,
            stack: 0,
            barThickness: 20,
            label: "Tacos",
            data: payload.map(user => user.tacos),
            fill: true,
            backgroundColor: 'rgba(242, 162, 60, 0.8)',
            borderColor: "grey",
            borderWidth: 1,
            },
            {
            id: 2,
            stack: 1,
            barThickness: 20,
            label: "Tequila",
            data: payload.map(user => user.tequila),
            fill: true,
            backgroundColor: 'rgba(195, 233, 76, 0.8)',
            borderColor: "grey",
            borderWidth: 1,
          }]
        }
      }
    case 'isLoadingRedux':
      return {
        ...state,
        isLoadingRedux: payload,
      }
    default:
      throw new Error();
  }
}

