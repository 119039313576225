import { Bar } from "react-chartjs-2";
import localforage from "localforage";
import { reducer } from '../../reducers';
import { initState } from '../../reducers/middleware';

import {Chart as ChartJS} from 'chart.js/auto' // eslint-disable-line no-unused-vars
import "./chart.css"
import { isEmpty } from "lodash";
import { useEffect, useState, useReducer } from "react";

const BarChart = (props) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const { isLoadingRedux } = props;
  const { charData } = state;
  const [isLoading, setIsLoading] = useState(true)
  async function fetchUsers() {
    localforage.getItem('user', (err, data) => {
      if (err) {
        console.log('error', err)
        return ""
      }
      try {
        fetch(`${process.env.REACT_APP_API_URL}/user/authenticated/getAll`, { 
          method: "POST",
          credentials: "include",
          mode: "cors",
          body: JSON.stringify({
            token: data.token 
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((d) => d.json())
        .then((json) => {
          if (!json.error) {
            dispatch({type: "setBarChart", payload: json?.users})
            dispatch({type: "setUsers", payload: json?.users})
          }
        })
        // maybe also log the error 
      } catch (error) {
        console.log(error)
      }
    })
    }
  useEffect(() => {
    if (!isEmpty(charData?.datasets)) {
      setIsLoading(false)
    }
      if (isLoading || isLoadingRedux) {
        fetchUsers()
      }
  }, [charData.datasets, isLoading, isLoadingRedux])
  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          font: {
            size: 14,
          },
          color: 'darkgray'
        },

        ticks: {
          font: {
            size: 14,
          },
          color: 'darkgray',
        },
      },

      y: {
        title: {
          font: {
            size: 14,
          },
          color: 'darkgray'
        },

        ticks: {
          beginAtZero: true,
          font: {
            size: 12,
          },
          color: 'darkgray'
        }
      }
    }
  }
  return (
    <div className="barChartContainer">
      {charData && charData.datasets?.length > 0 && <Bar data={charData} options={options} />}
    </div>
  );
}

export default BarChart;
