import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './input.css'

const Input = (props) => {
  const { backgroundColor, type, updater } = props;
  const [value, setValue] = useState(0);
  const handleUpdate = () => {
    confirmAlert({
      title: 'Confirm to submit',
      message: `Did you really have ${value} ${type}?`,
      buttons: [
        {
          label: 'No',
          onClick: () => setValue(0),
          className: 'confirm-no'
        },
        {
          label: 'Yes',
          onClick: () => {
            updater(type, value)
            setValue(0)
          },
          className: 'confirm-yes'
        },
      ]
    });
  }
  const handlePlus = () => {
    if (value >= 10) {
      return
    }
    setValue(value + 1)
  }
  const handleMinus = () => {
    if (value === 0) {
      return
    }
    setValue(value - 1)
  }
  return (
    <div className="inputContainer">
      <Button style={{ width: '40px', backgroundColor: `${backgroundColor}`, borderColor: 'white'}} onClick={handleMinus}>-</Button>
      <span className="value">{value}</span>
      <Button  style={{ marginRight: '15px', width: '40px', backgroundColor: `${backgroundColor}`, borderColor: 'white'}} onClick={handlePlus} bg="secondary">+</Button>
      <Button disabled={value === 0} className="updateButton" variant="dark" onClick={handleUpdate}>Update {type}</Button>
    </div>
  );
}

export default Input;
