import { GoogleLogout } from 'react-google-login';
import localforage from 'localforage';
import "./auth.css";
const Logout = (props) => {
  const { handleDispatch, setStatus } = props;
  const handleLogoutSuccess = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/user/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => {
      localforage.removeItem('user').then(() => {
        handleDispatch({type: 'removeUser', payload: {}})
        setStatus(false)
      })
    }).catch(e => console.log(e))
  }
  return (
    <div id="signOutButton">
      <GoogleLogout
        className="googleLogout"
        theme="dark"
        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Logout"
        onLogoutSuccess={handleLogoutSuccess}
      />
    </div>
  )
}

export default Logout;