import { useEffect, useReducer, useState } from 'react';
import localforage from 'localforage';
import { gapi } from 'gapi-script';
import { GiTacos } from 'react-icons/gi';
import { BiDrink } from 'react-icons/bi';

import Login from '../auth/login';
import Logout from '../auth/logout';
import './Home.css';
import { reducer } from '../../reducers';
import Input from '../inputs';
import BarChart from '../charts/BarChart';
import { initState } from '../../reducers/middleware';
import { Link } from 'react-router-dom';
import logo from '../../assets/landing-logo.png'
import footerLogo from "../../assets/horizontal-logo.png"


const App = () => {
  const [state, dispatch] = useReducer(reducer, initState)
  const [isLoggedIn, setLoginStatus] = useState(false);

  const { user, users, isLoadingRedux } = state;
  const handleDispatch = (data) => {
    const { type, payload } = data
    dispatch({type, payload})
  }

  useEffect(() => {
    const start= () => {
      gapi.client.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "profile",
      })
    }
    gapi.load('client:auth2', start)
  })

  const getUpdatedValue = (num, type) => {
    let out = 0;
    out = user[type] + parseInt(num);
    return out;
  }

  const handleInputUpdate = async (type, value) => {
    dispatch({type: 'isLoadingRedux', payload: true})
    localforage.getItem('user', (err, data) => {
      if (err) {
        console.log('error', err)
        return ""
      }
      try {
        const { id } = user;
        fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            token: data.token,
            [type]: getUpdatedValue(value, type)
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then((data) => {
          return data.json()
        }).then((userData) => {
          // const user = userData.find(u => u.id = id);
          const {users, user} = userData
          dispatch({type: 'setUser', payload: user })
          dispatch({type: "setBarChart", payload: users })
          dispatch({type: "setUsers", payload: users})
          dispatch({type: 'isLoadingRedux', payload: false})
        })
        // maybe also log the error 
      } catch (error) {
        console.log(error)
      }
    })
  }

  const getHeader = () => {
    const options = ["Hungry?", "Thirsty?"];
    const index = Math.floor(Math.random() * (1 - 0 + 1) + 0);
    return `${options[index]} ${user?.name.split(" ")[0]}`
  }
  return (
    <div className="App">
        {
          !isLoggedIn ? (
          <div className="loginContainer">
            <div className="login">
              <img alt="logo" className="landing-logo" src={logo} />
              <Login setStatus={setLoginStatus} handleDispatch={handleDispatch} />
            </div>
          </div>
        )
          :
          <>
          <div className="logout">
            <>
            <div style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "end",
              }}>
              <span className="header">{getHeader()}</span>
              {user?.photo && <img alt="user header" className="headerImg" src={user?.photo && user.photo} style={{ marginLeft: "10px"}} />}
            </div>
            <Logout setStatus={setLoginStatus} handleDispatch={handleDispatch} />
            </>
          </div>
            <div className="counter-container">
              {user?.tacos > 0 && <span><GiTacos />: {user.tacos}</span>}
              {user?.tequila > 0 && <span><BiDrink />: {user.tequila}</span>}
            </div>
          </>
        }
      {isLoggedIn && (
        <>
          <BarChart isLoadingRedux={isLoadingRedux} handleDispatch={handleDispatch} isLoggedIn={isLoggedIn} />
          <div className="trackerContainer">
            <Input type="tacos" backgroundColor="rgba(242, 162, 60, 0.8)" updater={handleInputUpdate} />
            <Input type="tequila" backgroundColor="rgba(195, 233, 76, 0.8)" updater={handleInputUpdate} />
          </div>
          {user?.email === "tylerdanielwork@gmail.com" && (
            <Link to="admin" state={{ users: users, user: user}}>Admin</Link>
          )}
          <div className="footer-logo-container">
            <img className="footer-logo" alt="footer logo" src={footerLogo} />
          </div>
        </>
      )}
    </div>
  );
}

export default App;
